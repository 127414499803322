import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchCategories(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post('/admin/get/categories', {})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  
  
    addCategories(ctx, requestBody) {
      return new Promise((resolve, reject) => {
        axios
          .post('admin/add/categories', requestBody)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    
  
    updateCategories(ctx, requestBody) {
      return new Promise((resolve, reject) => {
        axios
          .post('admin/update/category', requestBody)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    deleteCategories(ctx, requestBody) {
      return new Promise((resolve, reject) => {
        axios
          .post('admin/delete/categories', requestBody)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },



    addUser(ctx, requestBody) {
      return new Promise((resolve, reject) => {
        axios
          .post('admins/registerAssociate', requestBody)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
